import React,{useLayoutEffect} from 'react';
import "../css/Service.css"
import Servicedata from './Servicedata';
import Bounce from 'react-reveal/Bounce';


const Servicesecton = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});
  return (
    <>
    

      <section className='Service-Section-2'  style={{ paddingBottom: "20px" }}>
      <div style={{textAlign:"center", justifyContent:"center"}}>
<div className='NewAbout-Section-con-details-Info-con'>
                                            <h2 className='NewAboutUs-tagLlines' style={{textAlign:"center"}}>Our Services</h2>
                                            <h3 style={{textAlign:"center"}}>Our Services</h3>
                                            </div>
                                            </div>
        <div className='container'>
          <div className='row'>
            {
              Servicedata.map((Data) => {
                const { ServiceName, ServiceSubName, ServiceImage } = Data
                return (
                  <>

                    <div className='col-md-3'>
                      <div className='Service-Card-Body'>
                        <div className='Service-Card'>
                          <div className='Service-Card-Image'>
                            <img src={ServiceImage} alt="" />
                          </div>
                          <div className='Services-Card-Overlay'>
                            <div className='Card-Name'>
                              {ServiceName}
                              <span>{ServiceSubName}</span>
                            </div>
                            <div className='Show-Line'>
                              <span></span>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </>
                )
              })
            }
          </div>
        </div>
      </section>
  
    </>
  )
}

export default Servicesecton
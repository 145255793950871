import React from 'react';
import "../css/NewAboutUs.css"
import ab1 from "../asset/aboutus/about1.webp"
import ab2 from "../asset/aboutus/about2.webp"
import ab3 from "../asset/aboutus/ab3.webp"
import ab4 from "../asset/aboutus/ab4.webp"



const NewAboutUs = () => {
    return (
        <>
            <section className='NewAbout-Section-1'>
                <div className='container'>
                    <div className='NewAbout_Section-Con'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='NewAboutUS-Image-Main-con'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='NewAboutUS-Image-con'>
                                                <img src={ab3} />
                                            </div>
                                        </div>
                                        <div className='col-md-6 displaynone' >
                                            <div className='NewAboutUS-Image-Second-con'>
                                                <div className='NewAboutUS-Image-Second'>
                                                    <img src={ab2} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6 displaynone'>
                                            <div className='NewAboutUS-Image-Third-con'>
                                                <div className='NewAboutUS-Image-Third'>
                                                    <img src={ab1} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6 displaynone'>
                                            <div className='NewAboutUS-Image-Forth-con'>
                                                <img src={ab4}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='NewAbout-Section-con-details-Info'>
                                    <div className='NewAbout-Section-con-details-con'>
                                        <div className='NewAbout-Section-con-details-Info-con'>
                                            <h2 className='NewAboutUs-tagLlines paddingtopr' >About Us</h2>
                                            <h3 className='textcent'> Welcome to aarna swarna banquet hall</h3>
                                            <p style={{textAlign:"justify"}}> Welcome to The Aarna Swarna banquet, the best banquet in vasai, where dreams come to life and celebrations become cherished memories. Nestled in the heart of elegance, our best banquet in vasai is the perfect canvas for a myriad of events. Whether you're envisioning a fairytale wedding, a joyous birthday bash, a sophisticated corporate gathering, or a heartwarming family function, the best banquet in vasai is here to turn your vision into reality.</p>
                                            <p style={{textAlign:"justify"}}>Our best banquet in vasai is a harmonious blend of opulence and functionality. With versatile spaces, tasteful decor, and state-of-the-art facilities, we cater to the diverse needs of our clients. From intimate ceremonies to grand galas, we have the capacity to host events of varying scales, ensuring a seamless and memorable experience for all.
</p>
                                            <div className='NewAbout-Section-con-details-btn'>
                                                <button>
                                                    Read More
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default NewAboutUs
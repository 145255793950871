import React from 'react'
import logo from "../asset/logo.png"
import { Link } from "react-router-dom";
import ServiceDataa from "./ServiceDataa";
import NavDropdown from "react-bootstrap/NavDropdown";
import { LiaFacebookF,LiaTwitter,LiaInstagram } from 'react-icons/lia';
const Fotter = () => {
  return (
    <div>
      <footer className="section bg-black">
  <div className="footer-top section-padding">
    <div className="container">
      <div className="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n8">
        <div className="col mb-8">
          <div className="footer-widget footer-about">
            <div className="footer-logo textalign">
              <a href="#">
                <Link to="/" >
                <img className='footerlogo ' src={logo} alt="logo" />
                </Link>
              </a>
            </div>
            <p className='textalign'>
            Curating interiors that breathe life into rooms, blending style and comfort seamlessly.
            </p>
            <div className="social-icon-style textalign">
              
              <a className="google-plus" href="https://www.instagram.com/aarnaswarnabanquets/">
        
                <div className='fa1'>
                <  LiaInstagram style={{color:"black"}} />
                </div>
              </a>
              
              <a className="google-plus" href="https://www.facebook.com/aarnasuvarnabanquets/">
        
        <div className='fa1'>
        <LiaFacebookF style={{color:"black"}}  />
        </div>
      </a>
           
            </div>
          </div>
        </div>
        <div className="col mb-8">
          <div className="footer-widget footer-list">
            <h3 className="footer-title textalign">Useful Link</h3>
            <ul>
              <li>
                <a ><Link to="/"> Home</Link></a>
              </li>
              <li>
                <a ><Link to="/top-banquet-vasai">About us</Link></a>
              </li>
              <li>
                <a > <NavDropdown title="Services" id="collapsible-nav-dropdown">
                    {ServiceDataa.map((ele) => {
                      const { title, Slugs } = ele;
                      return (
                        <NavDropdown.Item key={Slugs} href={Slugs}>
                          <Link to={Slugs} style={{color:"black"}}>{title}</Link>
                        </NavDropdown.Item>
                      );
                    })}
                  </NavDropdown></a>
              </li>
              <li>

              </li>
              <li>
                <a > <Link to="/banquet-near-vasai"> Contact us</Link> </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col mb-8">
          <div className="footer-widget footer-list">
            <h3 className="footer-title textalign">Contact Us</h3>
            <ul>
              <li style={{color:"white"}}>
                <span className="title">T:</span>
                <span className="desc textalign">
                +91 866 999 4031</span>
                <br />
                <span className="desc textalign">+91 97674 16630</span>
              </li>
              <li>
                <span className="title">E:</span>
                <span className="desc textalign">
                aarnaswarnavasai@gmail.com
                </span>
              </li>
              <li>
                <span className="title">A:</span>
                <span className="desc textalign">
                  
                3rd floor, Unity Mall (previously Dattani square), Bhabhola - Papdy road, next to Smart Bazaar, Vasai west, Vasai-Virar, Maharashtra 401202
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="col mb-8">
          <div className="footer-widget footer-newsletter">
            <h3 className="footer-title textalign">Location</h3>
            <p className='textalign'>
              <div style={{borderRadius:"25px"}}>
              <iframe style={{borderRadius:"25px"}}
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3764.218296397705!2d72.80984702498488!3d19.359698281904194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7ae86926aaaab%3A0xd842232f1f2187ff!2sAarna%20Swarna%20Banquet%20Hall!5e0!3m2!1sen!2sin!4v1704865117920!5m2!1sen!2sin"/>
              </div>
            </p>
            
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="footer-bottom">
    <div className="container">
      <div className="copyright text-center">
       
        <div className='Copy-Right'>
                    <div className='Contact-Us-Copy' style={{color:"white"}}>
                        © Copyright
                        <strong>
                            <span style={{ color: "white" }}>AARNA SWARNA BANQUET</span>
                        </strong>
                        . All Rights Reserved
                    </div>
                    <div className="credits" style={{color:"white"}}>
                        Designed by
                        <a href="https://skdm.in/" style={{ color: "white" }}>
                            Shree Krishna Digital
                        </a>
                    </div>
                </div>
      </div>
    </div>
  </div>
</footer>

    </div>
  )
}

export default Fotter

import React, { useEffect, useState } from 'react';
import Hadder from '../components/Hadder';
import Fotter from '../components/Footer';
import ServiceDataa from '../components/ServiceDataa';
import { useLocation } from 'react-router-dom';
import lightGallery from 'lightgallery';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-fullscreen.css';
import 'lightgallery/css/lg-pager.css';
import 'lightgallery/css/lg-autoplay.css';
import G4 from "../asset/Gallery/G6.webp"
import { MetaTags } from 'react-meta-tags';
const Service = () => {
  useEffect(() => {
    // Initialize lightGallery
    lightGallery(document.getElementById('image-gallery'), {
      mode: 'lg-fade',
      thumbnail: true,
      zoom: false,
      autoplay: false,
    });
  }, []);

  const [edata, setEdata] = useState(ServiceDataa);

  const search = useLocation();
  const Path = search.pathname;

  const EventFdata = edata.find((e) => e.Slugs === Path);

  return (
    <div>
      <Hadder />
      <MetaTags>
        <title>{EventFdata.slug}</title>
        <meta title={EventFdata.slug} />
        <meta name="description" content={EventFdata.description} />
        <meta name="keywords" content="best-banquet-vasai,top-banquet-vasai,wedding-banquet-vasai,best-banquet-vasai-birthday-parties,vasai-banquet-hall-corporate-events,best-banquet-family-function-vasai,banquet-near-vasai" />
        <link rel="canonical" href="https://madhurafarms.com/" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content={EventFdata.slug} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://madhurafarms.com/" />
        <meta property="og:description" content={EventFdata.description} />
        <meta property="og:image" content="https://madhurafarms.com/static/media/logo.a70dbf41e465615a4172.png" />
      </MetaTags>
      <div
        className="breadcrumb-area breadcrumb-padding bg-img"
        style={{
          backgroundImage:
            `url(${G4})`,
        }}
      >
        <div className="container">
          <div className="breadcrumb-content text-center">
            <h1 style={{ color: 'white' }}>{EventFdata.bannertitle}</h1>
          </div>
        </div>
      </div>
      <section className="Service-Section-2">
        <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', paddingBottom: '50px' }}></div>
        <div className="container">
          <div id="image-gallery" className="row">
            {EventFdata.image.map((ele, index) => (
              <a
                key={index}
                className="col-md-4"
                style={{ paddingBottom: '20px' }}
                href={ele.image1}
                data-lg-size="1600-2400"
                data-lg-srcset={ele.image1}
              >
                <img src={ele.image1} style={{ height: '300px', width: '100%' }} alt={`Image ${index + 1}`} />
              </a>
            ))}
          </div>
        </div>
        <p className='container'style={{textAlign:"justify"}}>{EventFdata.peragraph}</p>
      </section>
      <Fotter />
    </div>
  );
};

export default Service;
